import React from 'react';
import s from './Profile.module.css'

export const Profile = () => {
    return (
        <div className={s.profile}>
            Hello my profile
        </div>
    );
};

